<template>
  <div>
    <div class="flix-text-right">
      <a href="#" class="flix-html-a flix-html-small" @click.prevent="open = !open">{{ $tc('message.messageTo', 1, {name: $tc('message.customer', 1) + ' #' + entry.booking_id}) }} <flixIcon :id="'chevron-up'" v-if="open" /> <flixIcon :id="'chevron-down'" v-else /></a>
    </div>
    <transition name="flixFadeIn">
      <select class="flix-form-control" v-if="open && notes.length" :key="open" @change="setMsg">
        <option value="">-- {{ notes.length }} {{ $tc('message.presets', notes.length) }} --</option>
        <option v-for="(n, index) in notes" :key="n.ID" :value="n.msg">{{ index + 1 }}) {{ n.msg.substr(0, 20) }}...</option>
      </select>
    </transition>
    <div class="flix-form-group">
      <textarea v-model="note" :placeholder="$tc('message.messageTo', 1, {name: $tc('message.customer', 1) + ' #' + entry.booking_id}) + ' ('+$t('message.optional')+')'" class="flix-form-control"></textarea>
      <transition name="flixFadeIn">
        <div class="flix-text-right" v-if="note" :key="typeof note">
          <a class="flix-html-a" href="#" @click.prevent="note = ''"><flixIcon class="flix-html-small" :id="'bin'" /></a>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    entry: Object,
    callback: Function
  },
  data () {
    return {
      note: '',
      notes: [],
      open: false
    }
  },
  watch: {
    'note' () { this.callback(this.note) }
  },
  methods: {
    setMsg (val) {
      if (val.target.value) {
        this.note = val.target.value
        this.open = false
      }
    },
    getLastNote () {
      this.$flix_post({
        url: 'notes/get_specific',
        data: {
          user: this.variables.user.md5_id,
          category: ['status_note']
        },
        callback: function (ret) { if (!ret.data[0] || !ret.data[1].length) { return false } this.notes = this.removeDublettes(ret.data[1]); this.getLastUserNote() }.bind(this)
      })
    },
    removeDublettes (notes) {
      var n = []
      var r = []
      notes.forEach(function (note) {
        if (n.indexOf(note.msg) === -1 && n.length <= 50) {
          n.push(note.msg)
          r.push(note)
        }
      })
      return r
    },
    getLastUserNote () {
      for (var i = 0; i < this.notes.length; i++) {
        if (this.notes[i].user === this.entry.ID) {
          this.note = this.notes[i].msg
          break
        }
      }
    }
  },
  mounted () {
    this.variables = this.$getUserVariables()
    this.getLastNote()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
